//components/ForgotPasswordForm.js
import React, { useState } from 'react';
import axios from 'axios';
import '../styles/ForgotPassword.css'

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false); // Para diferenciar entre mensaje de éxito o error

  const handleResetPassword = async (event) => {
    event.preventDefault(); // Evitar que el formulario se envíe de forma predeterminada

    setMessage(''); // Reiniciar mensaje
    setIsError(false);

    try {
      const response = await axios.post('http://20.163.180.10:5000/recuperar', {
        correo: email,
      });

      if (response.data.status === 'success') {
        alert('Éxito: Correo de recuperación enviado a tu email, revisa tu bandeja de correo no deseado.');
      } else {
        setIsError(true);
        setMessage(response.data.message || 'Error desconocido. Inténtalo más tarde.');
      }
    } catch (error) {
      setIsError(true);
      if (error.response) {
        // Manejar errores basados en el estado HTTP
        switch (error.response.status) {
          case 400:
            setMessage('Por favor, proporciona un correo válido.');
            break;
          case 404:
            setMessage('El correo ingresado no está registrado.');
            break;
          case 500:
            setMessage('Hubo un error interno en el servidor. Inténtalo más tarde.');
            break;
          default:
            setMessage('Ocurrió un error inesperado. Inténtalo de nuevo.');
        }
      } else {
        setMessage('No se pudo conectar con el servidor. Revisa tu conexión a internet.');
      }
    }
  };

  return (
    <form className="forgot-password-form" onSubmit={handleResetPassword}>
      <h2 className="title">Restablecer Contraseña</h2>
      <input
        type="email"
        className="input"
        placeholder="Correo electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {message && (
        <p className={`message ${isError ? 'error' : 'success'}`}>{message}</p>
      )}
      <button type="submit" className="forgotpassword-button">Enviar correo de restablecimiento</button>
    </form>
  );
};

export default ForgotPasswordForm;
