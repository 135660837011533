import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

const ProtectedRoute = ({ children, superuserOnly = false }) => {
  const { user } = useContext(AuthContext);

  if (!user) return <Navigate to="/login" />;
  if (superuserOnly && !user.tipo_usuario) return <Navigate to="/home" />;

  return children;
};

export default ProtectedRoute;