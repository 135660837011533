import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import MapComponent from '../components/MapComponent';
import Header from '../components/Header';
import useMapNavigation from '../hooks/UseMapNavigation';
import '../styles/MapStyle.css';
 

const Home = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { onMapLoad, panToLocation, setMapZoom, locateUser } = useMapNavigation();
  const [zoom, setZoom] = useState(13);
  const [center] = useState({ lat: 19.444048, lng: -99.11716 });
  const [userLocation, setUserLocation] = useState(null);
  const [showPolyline, setShowPolyline] = useState(true);
  
  const handleZoomIn = () => {
    const newZoom = zoom + 1;
    setZoom(newZoom);
    setMapZoom(newZoom);
  };

  const handleZoomOut = () => {
    const newZoom = zoom - 1;
    setZoom(newZoom);
    setMapZoom(newZoom);
  };


  const handleLocateLine4 = () => {
    panToLocation(center.lat, center.lng);
  };

  const handleToggleLine4 = () => {
    setShowPolyline(prevState => !prevState);
  };

  const handleLocateUser = () => {
    locateUser((lat, lng) => {
      setUserLocation({ lat, lng });
      panToLocation(lat, lng); // Movimiento suave para el mapa
    });
  };

  const handleManageConvoys = () => {
    if (user.tipo_usuario) {
      navigate('/manageconvoy');
    } else {
      alert('No tienes permisos para gestionar convoys.');
    }  };

  return (
    <div>
      <Header
        isSuperUser={user?.tipo_usuario} // Verifica si el usuario es superusuario
        onLogout={logout}
        onManageConvoys={handleManageConvoys}
      />

      <MapComponent 
        center={center} 
        zoom={zoom} 
        onMapLoad={onMapLoad} 
        panToLocation={panToLocation}
        setMapZoom={setMapZoom}
        locateUser={locateUser}
        userLocation={userLocation}
        showPolyline={showPolyline} // Pasa el estado de visibilidad como prop
      />
      <div className="controls-container">
        <button className="map-button" onClick={handleZoomIn}>Acercar</button>
        <button className="map-button" onClick={handleZoomOut}>Alejar</button>
        <button className="map-button" onClick={handleLocateUser}>Ubicar Usuario</button>
        <button className="map-button" onClick={handleToggleLine4}>{
          showPolyline ? 'Ocultar Línea 4' : 'Mostrar Línea 4'}
        </button>
        <button className="map-button" onClick={handleLocateLine4}>Ubicar Línea 4</button>
      </div>
    </div>
  );
};

export default Home;