//screens/ForgotPasswordForm.js
import React from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import '../styles/ForgotPassword.css'

const ForgotPasswordScreen = () => {
  return (
    <div className="forgot-password-screen">
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordScreen;
