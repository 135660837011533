import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  
  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || null;
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si hay un token en localStorage al cargar la app
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const storedToken = localStorage.getItem('token');

    if (storedUser && storedToken) {
      setUser(storedUser);
      setToken(storedToken);
    }
  }, []);

  const login = async (correo, contraseña) => {
    try {
      const response = await axios.post('http://20.163.180.10:5000/login', { correo, contraseña, cliente: 'web' });

      if (response.data.status === 'success') {
        const { usuario } = response.data;
        const token = response.data.token;

        setUser(usuario);
        setToken(token);
        localStorage.setItem('user', JSON.stringify(usuario));
        localStorage.setItem('token', token);

        window.alert(`Bienvenido, ${response.data.usuario.nombre}`);

        navigate('/home'); // Redirigir al home
      } else {
        throw new Error(response.data.message || 'Error desconocido');
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            window.alert('Error de autenticación: Contraseña incorrecta');
            break;
          case 404:
            window.alert('Error de autenticación: Usuario no registrado');
            break;
          default:
            window.alert(`Error: ${error.response.data.message || 'Error inesperado'}`);
        }
      } else {
        console.error('Error al conectar con la API:', error);
        window.alert('Error de conexión: No se pudo conectar con el servidor');
      }
      console.error('Error de autenticación:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/login'); // Redirigir al login
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;