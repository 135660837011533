//src/screens/UserProfileScreen.js
import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthProvider';
import UserProfileForm from '../components/UserProfileForm';
import Header from '../components/Header';
import axios from 'axios';
import '../styles/UserProfileScreen.css';
import { useNavigate } from 'react-router-dom'; // Importar hook de navegación


const UserProfileScreen = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate(); // Hook para navegar entre rutas
  const [userData, setUserData] = useState(null); // Estado para almacenar los datos del usuario
  const [loading, setLoading] = useState(true); // Estado para manejar la carga


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log(user);
        const response = await axios.get('http://20.163.180.10:5000/obtener_datos_usuario', {
          params: { user_id: user.id_usuario },
        });

        if (response.data.status === 'success') {
          setUserData(response.data.usuario); // Guardar los datos del usuario en el estado
        } else {
          alert('Error al obtener los datos del usuario: ' + response.data.message);
        }
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
        alert('Error al conectar con el servidor');
      } finally {
        setLoading(false); // Finalizar la carga
      }
    };

    fetchUserData();
  }, [user]);

  const handleSaveChanges = async (data) => {
    try {
      const response = await axios.put('http://20.163.180.10:5000/actualizar_usuario', {
        id_usuario: user.id_usuario,
        ...data,
      });

      if (response.data.status === 'success') {
        alert('Datos actualizados correctamente');
      } else {
        alert('Error al actualizar los datos');
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      alert('Error al conectar con el servidor');
    }
  };

  // Validar el formato de la contraseña
  const validarContraseña = (contraseña) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(contraseña);
  };

  const handleChangePassword = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    // Validar si los campos están vacíos
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      alert('Todos los campos son obligatorios');
      return;
    }
    
    if (!validarContraseña(newPassword)) {
        alert(
          'La contraseña debe tener al menos 8 caracteres, incluir una mayúscula, una minúscula, un número y un caracter especial.'
        );
        return;
    }

    if (newPassword !== confirmNewPassword) {
      alert('Las contraseñas no coinciden');
      return;
    }

    try {
      const response = await axios.put('http://20.163.180.10:5000/cambiar_contraseña', {
        id_usuario: user.id_usuario,
        current_password: currentPassword,
        new_password: newPassword,
      });

      if (response.status === 200 && response.data.status === 'success') {
        alert('Contraseña actualizada correctamente');
      } else if (response.status === 404) {
        alert('Usuario no encontrado');
      } else if (response.status === 401) {
        alert('La contraseña actual es incorrecta');
      } else {
        alert(response.data.message || 'Error desconocido al cambiar la contraseña');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
  
      if (error.response) {
        // Errores específicos del servidor
        switch (error.response.status) {
          case 400:
            alert('La contraseña nueva no puede ser igual a la actual');
            break;
          case 404:
            alert('Usuario no encontrado');
            break;
          case 401:
            alert('La contraseña actual es incorrecta');
            break;
          case 500:
            alert('Error interno del servidor');
            break;
          default:
            alert('Error al procesar la solicitud');
        }
      } else if (error.request) {
        // Errores de red
        alert('Error de red. Verifique su conexión a internet');
      } else {
        // Otros errores
        alert('Error desconocido al conectar con el servidor');
      }
    }
  };

  if (loading) {
    return <div>Cargando...</div>; // Mostrar un mensaje de carga mientras se obtienen los datos
  }

  const handleManageConvoys = () => {
    if (user.tipo_usuario) {
      navigate('/manageconvoy');
    } else {
      alert('No tienes permisos para gestionar convoys.');
    }  };

  return (
    <div className="user-profile-screen">
      <Header
        isSuperUser={user?.tipo_usuario} // Verifica si el usuario es superusuario
        onLogout={logout}
        onManageConvoys={handleManageConvoys}
      />
      <button className="return-button" onClick={() => navigate('/home')}>
        Mapa
      </button>
      
      <div className="user-profile-container">
        {userData ? (
            <UserProfileForm 
            user={userData}
            onSaveChanges={handleSaveChanges}
            onChangePassword={handleChangePassword}
            />
        ) : (
            <p>No se pudieron cargar los datos del usuario</p>
        )}
      </div>
    </div>
  );
};

export default UserProfileScreen;