//src/components/UserProfileForm.js
import React, { useState } from 'react';

const UserProfileForm = ({ user, onSaveChanges, onChangePassword }) => {
  const [nombre, setNombre] = useState(user.nombre);
  const [correo, setCorreo] = useState(user.correo);
  const [primerApellido, setPrimerApellido] = useState(user.primer_apellido);
  const [segundoApellido, setSegundoApellido] = useState(user.segundo_apellido);
  const [editable, setEditable] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSave = () => {
    onSaveChanges({
      nombre,
      correo,
      primer_apellido: primerApellido,
      segundo_apellido: segundoApellido,
    });
    setEditable(false);
  };

  const handlePasswordChange = () => {
    onChangePassword({
      currentPassword,
      newPassword,
      confirmNewPassword,
    });
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <div className="user-profile-form">
      <h2>Perfil de Usuario</h2>
      <div>
        <label>Nombre:</label>
        <input
          type="text"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div>
        <label>Primer Apellido:</label>
        <input
          type="text"
          value={primerApellido}
          onChange={(e) => setPrimerApellido(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div>
        <label>Segundo Apellido:</label>
        <input
          type="text"
          value={segundoApellido}
          onChange={(e) => setSegundoApellido(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div>
        <label>Correo:</label>
        <input
          type="email"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
          disabled
        />
      </div>
      <div>
        <button onClick={() => (editable ? handleSave() : setEditable(true))}>
          {editable ? 'Guardar' : 'Editar'}
        </button>
      </div>

      <h3>Cambiar Contraseña</h3>
      <div>
        <label>Contraseña Actual:</label>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Nueva Contraseña:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Confirmar Nueva Contraseña:</label>
        <input
          type="password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
      </div>
      <button onClick={handlePasswordChange}>Guardar Nueva Contraseña</button>
    </div>
  );
};

export default UserProfileForm;