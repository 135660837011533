// src/components/LoginForm.js
import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { login } = useContext(AuthContext); // Usa el login del contexto
  const navigate = useNavigate();
  const [correo, setCorreo] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault(); // Evitar recarga de página
    setError(null); // Reiniciar el error antes de intentar el login
  
    try {
      await login(correo, contraseña);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setError('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
            break;
          case 404:
            setError('Usuario no registrado. Verifica tu correo o regístrate.');
            break;
          default:
            setError('Ocurrió un error inesperado. Inténtalo más tarde.');
        }
      } else {
        console.error('Error al conectar con el servidor:', err);
        setError('No se pudo conectar con el servidor. Revisa tu conexión a internet.');
      }
    }
  };
  

  return (
    <form onSubmit={handleLogin} className="login-form">
      <input
        type="email"
        placeholder="Correo electrónico"
        value={correo}
        onChange={(e) => setCorreo(e.target.value)}
        className="login-input"
        required
      />
      <input
        type={showPassword?'text':'password'}
        placeholder="Contraseña"
        value={contraseña}
        onChange={(e) => setContraseña(e.target.value)}
        className="login-input"
        required
      />
      <div className="password-toggle">
        <input 
          type="checkbox" 
          checked={showPassword}
          onChange={() => setShowPassword(!showPassword)} 
        />
        <label>Mostrar Contraseña</label>
      </div>
      {error && <p className="error-message">{error}</p>}
      <button 
        type="submit" 
        className="login-button"
      >
          Iniciar sesión
      </button>
      <button
        type="button"
        className="download-app-button"
        onClick={() => navigate('/downloadapp')}
      >
        Descargar App
      </button>

    </form>
  );
};

export default LoginForm;