import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import './App.css';
import LoginScreen from './screens/LoginScreen';
import SignupScreen from './screens/SignupScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ManageConvoysScreen from './screens/ManageConvoyScreen';
import ResetPassword from './screens/ResetPasswordScreen';
import DownloadApp from './screens/DownloadApp'
import MapScreen from "./screens/MapScreen";
import UserProfileScreen from './screens/UserProfileScreen';
import { AuthProvider } from './context/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';



function App() {
  return (
    <Router>
      <AuthProvider>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/" element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="forgotpassword" element= {<ForgotPasswordScreen/>}/>
          <Route path="/resetear/:token" element={<ResetPassword />} />
          <Route path="/downloadapp" element={<DownloadApp/>}/>
          <Route path="/login" element={<LoginScreen />} />

          {/* Rutas protegidas */}
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <MapScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/userprofile"
            element={
              <ProtectedRoute>
                <UserProfileScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manageconvoy"
            element={
              <ProtectedRoute superuserOnly={true}>
                <ManageConvoysScreen />
              </ProtectedRoute>
            }
          />
        </Routes>
        </LoadScript>
      </AuthProvider>
    </Router>
  );
}

export default App;